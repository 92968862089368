.quantity-widget {
  display: flex;
  align-items: center;

  %btn {
    border: 1px solid #b0b0b0;
    width: 26px;
    height: 26px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: none;

      &:first-child {
        display: inline;
      }
    }

    &:hover {
      img {
        display: inline;

        &:first-child {
          display: none;
        }
      }
    }

  }

  &__incr-btn {
    @extend %btn;
  }

  &__decr-btn {
    @extend %btn;
  }

  input {
    height: 26px;
    width: 40px;
    text-align: center;
    font-size: 16px;
    padding: 0;
    border: 1px solid #b0b0b0;
    border-right: none;
    border-left: none;
  }

  &--offer-page {
    input {
      height: 50px;
      width: 70px;
      border: none;
      margin: 0 10px;
      box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, .25);
    }
  }
}
.articles {
  margin-bottom: 120px;

  &:after {
    content: '';
    clear: both;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__item {
    width: 50%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    padding-right: 2%;
    border-bottom: 1px solid #b0b0b0;
    box-sizing: border-box;
    height: 180px;
  }

  &__item-img {
    margin-right: 20px;
  }

  &__item-title {
    margin-bottom: 7px;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }

  &__item-date {
    margin-bottom: 7px;
  }
}
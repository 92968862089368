.certificates-reviews {
  $this: &;
  margin-top: 20px;

  &__tab-switcher {
    display: flex;
    align-items: flex-start;
  }

  &__tab-btn {
    margin-right: 8px;
    text-align: center;
    width: 120px;
    line-height: 30px;
    color: #ffffff;
    background-color: #373743;
    cursor: pointer;

    &:not(&--active) {
      &:hover {
        background-color: #575763;
      }
    }

    &--active {
      background-color: #ffcc1c;
      color: #2e2e2e;
      padding-bottom: 5px;
    }
  }

  &__tab-content {
    padding: 10px 20px;
    border: 1px solid #ffcc1c;
  }

  &__tab {
    display: none;

    &--active {
      display: block;
    }
  }

  &__certificates {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__one-cert {
    width: 19%;
  }

  &__one-cert-link {
    text-decoration: none;
    display: block;

    img {
      max-width: 100%;
    }
  }

  &__one-review {
    border: 1px solid #b0b0b0;
    border-top: 4px solid #ffcc1c;
    padding: 12px 98px 18px 28px;
    font: 14px/18px RobotoCondensedLight;
    color: #2e2e2e;
    margin: 0 0 18px 0;
  }

  &__one-review-name {
    font: italic 16px/18px RobotoCondensedRegular;
    color: #a2a2a2;
  }

  &__one-review-date {
    font: italic 13px/18px RobotoCondensedRegular;
    color: #32b153;
    margin: 0 0 4px 0;
  }
}

.product-review-form {
  $this: &;

  &__success-msg {
    text-align: center;
    font-size: 20px;
    line-height: 50px;
    display: none;
  }

  &__error {
    color: red;
    margin: 5px 0;
  }

  &__form {
    width: 1137px;
    height: 258px;
    border: 1px solid #b0b0b0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  &__title {
    height: 52px;
    background: #32b153;
    padding: 0 24px;
    font: 16px/52px RobotoCondensedRegular;
    color: #fff;
    text-transform: uppercase;
  }

  &__left-block {
    width: 270px;
    padding: 0 0 0 24px;
    float: left;
  }

  &__p {
    font: 16px/18px RobotoCondensedRegular;
    margin: 0;
    padding: 20px 0 7px 10px;
    color: #373737;
  }

  &__name {
    width: 229px;
    height: 36px;
    border: 1px solid #b0b0b0;
    outline: none;
    font: 16px RobotoCondensedLight;
    color: #6f6f6f;
    padding: 0 8px;
    margin: 0 0 12px 0;
  }

  &__label {
    display: block;
    font: 16px/18px RobotoCondensedRegular;
    height: 40px;
    margin: 0 0 12px 0;
  }

  &__agreement {
    display: none;

    &:checked + #{$this}__custom-checkbox {
      background: #373737;
      box-shadow: inset 0 0 0 3px #fff;
    }

    &[data-error] + #{$this}__custom-checkbox{
      border-color: red;
    }
  }

  &__custom-checkbox {
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid #b0b0b0;
    float: left;
    margin: 6px 10px 0 0;
  }

  &__agreement-text {
    display: block;
    float: left;
  }

  &__submit {
    width: 250px;
    height: 40px;
    font: 13px/40px RobotoCondensedRegular;
    color: #2e2e2e;
    text-align: center;
    text-transform: uppercase;
    background: #ffcc1c;
    cursor: pointer;
    border: none;

    &:hover {
      background: #ffa71c;
    }
  }

  &__textarea {
    width: 800px;
    height: 132px;
    border: 1px solid #b0b0b0;
    outline: none;
    font: 16px RobotoCondensedLight;
    color: #6f6f6f;
    padding: 4px 8px;
    margin: 0 0 12px 0;
    resize: none;

    &[data-error] {
      border-color: red;
    }
  }

  &[data-state='success-send'] {
    #{$this}__success-msg {
      display: block;
    }

    #{$this}__form {
      display: none;
    }
  }
}
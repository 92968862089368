header {
  top: -100%;

  &.fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background: #ffffff;
    transition: top .3s;

    .green_menu_wrap {
      display: none;
    }

    .top_row_wrap {
      display: none;
    }

    .breadcrumbs_wrap {
      display: none;
    }
  }

  .top_row_wrap {
    width: 100%;
    border-bottom: 1px solid #e7e7e7;

    .top_row {
      width: 1210px;
      margin: 0 auto;

      %link {
        display: inline-block;
        font-size: 14px;
        color: #9f9f9f;
        text-decoration: underline;
        cursor: pointer;
        font-family: RobotoCondensedRegular, serif;
        padding: 10px 0;

        &:hover {
          text-decoration: none;
        }

        a {
          font-size: 14px;
          color: #9f9f9f;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .entry_link {
        @extend %link;
        margin-right: 20px;
      }

      .registration_link {
        @extend %link;
      }

      .lc_link {
        @extend %link;
      }

      .exit_link {
        @extend %link;
      }

      .email_address {
        display: inline-block;
        float: right;
        padding: 10px 0;

        .top-mail {
          font-size: 14px;
          color: #373743;
          text-decoration: underline;
          font-family: RobotoCondensedRegular, serif;
          background: url(/local/templates/krep-shop/images/header/mail_icon.png) no-repeat left center;
          padding-left: 30px;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .main_block_wrap {

    .main_block {
      width: 1210px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        padding: 16px 0;
        display: inline-block;
        margin: 0 40px 0 0;
        vertical-align: top;
      }

      .phones {
        display: inline-block;
        vertical-align: top;
        padding: 30px 0;
        width: 185px;

        img {
          margin: 0 20px 0 0;
        }

        .numbers {
          display: inline-block;
          vertical-align: middle;
          width: 129px;
          padding-top: 10px;
          font-size: 17px;
          color: #373743;
          font-weight: bold;
          font-family: RobotoCondensedRegular, serif;
        }
      }

      .header-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 150px;
      }

      .send-request {
        width: 100%;
        margin-bottom: 10px;
        text-transform: uppercase;
        color: white;
        background: #08b04d;
        text-align: center;
        font-family: RobotoCondensedRegular, serif;
        cursor: pointer;
        font-size: 13px;
        padding: 4px 0;

        &:hover {
          background: #00d858;
        }
      }

      .callback_button {
        width: 100%;
        text-transform: uppercase;
        color: white;
        background: #08b04d;
        text-align: center;
        font-family: RobotoCondensedRegular, serif;
        cursor: pointer;
        font-size: 13px;
        padding: 4px 0;

        &:hover {
          background: #00d858;
        }
      }

      .work_time_wrap {
        display: inline-block;

        .work_time_title {
          font-size: 14px;
          color: #2e2e2e;
          font-family: RobotoCondensedBold, serif;
        }

        .work_time {
          font-size: 13px;
          color: #2e2e2e;
          font-family: RobotoCondensedRegular, serif;
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }

          &.red {
            color: #ff5151;
          }
        }
      }

      .basket_wrap {
        display: inline-block;
        vertical-align: top;
        margin: 10px 0 0 0;
        text-decoration: none;
        float: right;

        a {
          text-decoration: none;
        }

        .basket {
          font-size: 13px;
          color: white;
          font-family: RobotoCondensedRegular, serif;
          text-transform: uppercase;
          padding: 14px 35px 14px 56px;
          background: #373743 url("/local/templates/krep-shop/images/header/basket_icon.png") no-repeat 15px 10px;
          margin-bottom: 5px;
        }

        &:hover {
          .basket {
            background-color: #575763;
          }
        }

        .sum_wrap {


          .name {
            font-size: 13px;
            color: #373737;
            font-family: RobotoCondensedRegular, serif;
            font-weight: bold;
            width: 60px;
            display: inline-block;
          }

          .value {
            font-family: RobotoCondensedBold, serif;
            font-size: 13px;
            color: #08b04d;
            width: 73px;
            text-align: right;
            display: inline-block;
          }
        }
      }
    }
  }

  .orange_block_wrap {
    background: #ffde00;

    .orange_block {
      width: 1210px;
      margin: 0 auto;
      background-color: #ffcc00;

      .catalog_menu {
        display: inline-block;
        vertical-align: top;
        margin-right: 60px;

        .icon {
          display: inline-block;
          width: 38px;
          height: 38px;
          border-radius: 40px;
          background: #373743 url(/local/templates/krep-shop/images/header/menu_icon.png) no-repeat center;
          margin: 10px 15px;
          vertical-align: top;
        }

        .text {
          display: inline-block;
          vertical-align: top;
          font-size: 17px;
          color: white;
          text-transform: uppercase;
          font-family: RobotoCondensedBold, serif;
          margin-top: 16px;
        }
      }

      .find_block {
        display: inline-block;
        vertical-align: top;
        background: #ffde00;

        form {
          position: relative;
          padding: 10px 0;
          margin: 0;

          .search_row {
            width: 925px;
            height: 39px;
            margin-left: 10px;
            outline: none;
            font-size: 13px;
            color: #858585;
            font-style: italic;
            font-family: RobotoCondensedRegular, serif;
            padding-left: 15px;
          }

          .find_button {
            position: absolute;
            font-size: 0;
            background: white url("/local/templates/krep-shop/images/header/magnifier.png") no-repeat;
            height: 26px;
            width: 26px;
            border: none;
            outline: none;
            top: 17px;
            right: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
footer {
  .menu-wrapper {
    display: inline-block;
    vertical-align: top;
    margin: 15px 0 0 0;

    .column {
      display: inline-block;
      vertical-align: top;

      &.first_col {
        margin-right: 60px;
      }

      .item {
        margin-bottom: 5px;

        a {
          text-decoration: none;
          font-size: 13px;
          color: rgb(55, 55, 67);
          text-transform: uppercase;
          font-family: RobotoCondensedBold;

          &:hover {
            color: #08b04d;
          }
        }
      }
    }
  }
  .user-acceptance {
    font: 14px/14px RobotoCondensedRegular;
    margin-top: 10px;
  }
}
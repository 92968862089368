.payment-options-block-hsh.payment-options-block-hsh-2.main-page {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .payment-options-item-hsh {
    width: 220px;
  }
}

.main-page__certificates-reviews {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.mp-certificates {
  width: 50%;
  padding-right: 1%;
  box-sizing: border-box;

  h3 {
    padding-bottom: 5px;
    border-bottom: 4px solid #08b04d;
    text-transform: uppercase;
    font: 20px RobotoCondensedRegular;

  }

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 2px;
      background-color: #08b04d;
      height: 75%;
      top: 0;
      bottom: 0;
      right: -6px;
      margin: auto 0;
    }
  }

  &__one-item {
    width: 49%;
  }

  &__link {
    text-decoration: none;
    display: block;

    img {
      max-width: 100%;
    }
  }
}

.mp-reviews {
  width: 50%;
  padding-right: 1%;
  box-sizing: border-box;

  h3 {
    padding-bottom: 5px;
    border-bottom: 4px solid #08b04d;
    text-transform: uppercase;
    font: 20px RobotoCondensedRegular;
  }

  &__list {
    padding: 0 15px;
    box-sizing: border-box;
  }

  &__one-item {
    border: 1px solid #b0b0b0;
    border-top: 4px solid #ffcc1c;
    padding: 12px 98px 18px 28px;
    font: 14px/18px RobotoCondensedLight;
    color: #2e2e2e;
    margin: 0 0 18px 0;
  }

  &__one-item-name {
    font: italic 16px/18px RobotoCondensedRegular;
    color: #a2a2a2;
  }

  &__one-item-date {
    font: italic 13px/18px RobotoCondensedRegular;
    color: #32b153;
    margin: 0 0 4px 0;
  }
}

.mp-articles {
  margin-top: 20px;

  &__title {
    padding-bottom: 5px;
    border-bottom: 4px solid #08b04d;
    text-transform: uppercase;
    font: 20px RobotoCondensedRegular;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__item {
    width: 49%;
  }

  &__article-title {
    margin-bottom: 7px;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }

  &__article-date {
    margin-bottom: 7px;
  }

  &__all-link {
    display: inline-block;
    margin-top: 15px;
    padding: 7px 10px;
    text-transform: uppercase;
    color: #fff !important;
    background: #08b04d;
    font-size: 13px;
    text-align: center;
    font-family: RobotoCondensedRegular,serif;
    text-decoration: none;
  }
}
.main-big-data {
  width: 930px;

  &__preloader {
    height: 250px;
    line-height: 250px;
    font-size: 24px;
    text-align: center;

    &.hide {
      display: none;
    }
  }

  &__wrapper {
    margin: 10px 0 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;
  }

  &__slider-container {
    display: flex;
    flex-wrap: nowrap;
  }

  &__one-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    flex-shrink: 0;
  }

  &__image-wrapper {
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
  }

  &__name {
    margin-top: 10px;
    height: 20px;
    display: block;
  }

  &__preview-text {
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
    height: 50px;
    display: block;
    overflow: hidden;
  }

  &__detail-btn {
    font: 14px RobotoCondensedRegular;
    color: #2e2e2e;
    width: 159px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #ffcc1c;
    transition: 0.4s background-color;
    margin-top: 30px;
    text-transform: uppercase;

    &:hover {
      background-color: #ffa71c;
    }
  }

  &__slider-left-right {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
  }

  &__slider-left {
    width: 32px;
    height: 32px;
    background: url("/img/slider-left.png") no-repeat center;
    transition: transform .3s;
    cursor: pointer;

    &:hover {
      transform: rotateX(180deg);
      background: url("/img/slider-left-a.png") no-repeat center;
    }
  }

  &__slider-right {
    width: 32px;
    height: 32px;
    background: url("/img/slider-right.png") no-repeat center;
    transition: transform .3s;
    cursor: pointer;

    &:hover {
      transform: rotateX(180deg);
      background: url("/img/slider-right-a.png") no-repeat center;
    }
  }

  &__slider-pagination {
    display: flex;
    height: 20px;
    position: absolute;
    width: 100%;
    bottom: 6px;
    align-items: center;
    justify-content: center;

    &:after {
      height: 1px;
      width: 100%;
      top: 0;
      bottom: 0;
      margin: auto;
      content: '';
      display: block;
      background-color: #b0b0b0;
      position: absolute;
    }
  }

  &__slider-pagination-element {
    width: 11px;
    height: 11px;
    margin: 0 4px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #b0b0b0;
    background: #f4f4f4;
    position: relative;
    z-index: 10;

    &--active {
      background: #ffcc1c;
    }
  }
}
.step-of-thread-table {
  border-collapse: collapse;
  margin: 0 auto;
  font-size: 14px;

  td, th {
    border: 1px solid black;
    text-align: center;
    padding: 15px 40px;
  }

  th {
    font-size: 16px;
  }
}
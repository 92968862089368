.offer-page {
  $this: &;
  margin-bottom: 100px;

  %image-center {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 95%;
      max-height: 95%;
    }
  }

  &__main-info {
    display: flex;
  }

  &__images {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 18px;
    padding-right: 24px;
    margin-right: 24px;
    width: 421px;
    border-right: 1px solid #dfdfdf;
    box-sizing: border-box;
  }

  &__main-image {
    @extend %image-center;
    width: 262px;
    height: 258px;
    border: 1px solid #b0b0b0;
    box-shadow: 0 0 4px #b0b0b0;
  }

  &__more-images {
    overflow: hidden;
  }

  &__more-images-slider {
    height: 200px;
    overflow: hidden;
  }

  &__more-images-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  &__more-images-top {
    width: 25px;
    height: 15px;
    background: url(/img/m-up.png) no-repeat center;
    cursor: pointer;
    transition: .4s opacity;
    opacity: .4;
    position: relative;
    z-index: 10;
    margin: 0 auto 10px;

    &:hover {
      opacity: 1;
    }
  }

  &__more-images-bottom {
    width: 25px;
    height: 15px;
    background: url(/img/m-down.png) no-repeat center;
    cursor: pointer;
    transition: .4s opacity;
    opacity: .4;
    position: relative;
    z-index: 10;
    margin: 10px auto 0;

    &:hover {
      opacity: 1;
    }
  }

  &__one-more-image {
    @extend %image-center;
    width: 90px;
    height: 90px;
    border: 1px solid #d8d8d8;
    cursor: pointer;
    flex-shrink: 0;
    box-sizing: border-box;

    &.active {
      border: 1px solid #ffd000;
    }
  }

  &__data {
    align-self: stretch;
    padding-right: 20px;
    border-right: 1px solid #dfdfdf;
    padding-top: 10px;
    width: 400px;
    box-sizing: border-box;
  }

  &__price {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  &__old-price {
    font-size: 18px;
    color: #9d9d9d;
    text-decoration: line-through;
  }

  &__one-prop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;
  }

  &__prop-dotes {
    position: absolute;
    bottom: 2px;
    left: 0;
  }

  &__prop-name {
    background: #ffffff;
    position: relative;
    z-index: 5;
    padding-right: 4px;
    font-weight: bold;
  }

  &__prop-value {
    background: #ffffff;
    position: relative;
    z-index: 5;
    padding-left: 4px;
  }

  &__buy {
    padding-left: 20px;
    display: flex;
    align-items: center;
    align-self: flex-start;
  }

  &__quantity-widget {
    margin-right: 15px;
  }

  &__buy-btn {
    background: #ffcc1c;
    cursor: pointer;
    text-align: center;
    padding: 10px 64px;
    color: #000000;
    line-height: 34px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__one-click-buy-btn {
    display: flex;
    width: 100%;
    margin-top: 10px;
    padding-left: 20px;
    font-size: 16px;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__social {
    margin-top: 20px;
    padding-left: 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  &__social-icons {
    margin-left: 10px;
  }

  &__detail-icons {
    height: 48px;
    margin: 14px 0;
    display: flex;
    padding-left: 440px;

    & > a {
      display: inline-block;
      position: relative;
      z-index: 10;
      float: left;
      width: 90px;
      height: 48px;
      padding: 0 16px 0 64px;
      transition: 0.4s;
      overflow: hidden;
      text-decoration: none;
      font: 16px/48px RobotoCondensedRegular;
      margin: 0 10px 0 0;
      color: #2e2e2e;
      border: 1px solid #d4d4d4;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 48px;
        height: 48px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &.di-dev:before {
        background-image: url(/img/truck.png);

      }

      &.di-pay:before {
        background-image: url(/img/pay.png);

      }

      &.di-sert:before {
        background-image: url(/img/sertifications.png);

      }

      &.di-ret:before {
        background-image: url(/img/refresh.png);

      }
    }

    & > a:hover {
      overflow: visible;
      background-color: #ffa71c;

      #{$this}__di-text {
        opacity: 1;
      }
    }
  }

  &__di-text {
    font: 13px/16px RobotoCondensedLight;
    color: #fff;
    background: #373737;
    width: 126px;
    padding: 17px 22px 14px 22px;
    position: absolute;
    left: 0;
    top: 48px;
    transition: 0.4s;
    opacity: 0;
  }

  &__description {
    margin-top: 30px;
  }

  &__description-title {
    line-height: 36px;
    font-weight: bolder;
    font-size: 18px;
    padding-left: 20px;
    background: #ffcc1c;
  }

  &__description-content {
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  &__description-wrapper {
    width: 50%;
    padding: 10px 20px 50px;
    border-right: 1px solid #dfdfdf;
  }
}

.one-click-buy-popup {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 440px;
  height: 442px;
  border: 1px solid #b0b0b0;
  box-shadow: 0 0 8px #b0b0b0;
  font: 16px RobotoCondensedRegular;
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: .4s;
  box-sizing: border-box;
  padding-top: 35px;

  &.loaded {
    z-index: 30;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &.success {
    width: 400px;
    height: 200px;
  }

  &__overlay {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    visibility: hidden;
    opacity: 0;
    transition-property: opacity, visibility;
    transition-duration: .4s;

    &.loaded {
      z-index: 20;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__close {
    position: absolute;
    right: 4px;
    top: -4px;
    font-size: 26px;
    font-family: Verdana, serif;
    color: #000;
    cursor: pointer;
  }

  &__wrapper {
    display: none;

    &.active {
      display: block;
    }
  }

  form {
    padding: 0 15px;
  }

  input {
    height: 60px;
    width: 100%;
    padding-left: 15px;
    box-sizing: border-box;
    margin-bottom: 15px;

    &.error {
      border-color: red;
    }
  }

  textarea {
    width: 100%;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
    padding: 10px;
    font: 16px RobotoCondensedLight;
  }

  &__notice {
    margin-top: 10px;
    text-align: center;
    padding: 0 20px;
  }

  button {
    background: #ffcc1c;
    cursor: pointer;
    text-align: center;
    padding: 10px 64px;
    color: #000000;
    line-height: 34px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 15px auto 0;
    border: none;
    display: block;
  }

  &__success {
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;

    &.active {
      display: flex;
    }
  }
}

.offer-page-oferta {
  font-size: 16px;
  margin: 5px 0;
  font-weight: bold;
  color: red;
  text-transform: none;
  font-family: RobotoCondensedLight;
}
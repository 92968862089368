.contacts {

  &__tabs {
    display: flex;
    align-items: center;
  }

  &__one-tab {
    cursor: pointer;
    padding: 0 10px;
    color: #ffffff;
    background-color: #08b04d;
    line-height: 40px;
    margin-right: 4px;
    font-size: 17px;

    &.active {
      background-color: #00d858;
    }
  }

  &__tabs-content {
    margin-top: 20px;
  }

  &__one-content {
    display: none;

    &.active {
      display: block;
    }
  }
}
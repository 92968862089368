.request-overlay {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  visibility: hidden;
  opacity: 0;
  transition-property: opacity, visibility;
  transition-duration: .4s;

  &.loaded {
    z-index: 20;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.request-popup {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 605px;
  height: 600px;
  border: 1px solid #b0b0b0;
  box-shadow: 0 0 8px #b0b0b0;
  font: 16px RobotoCondensedRegular;
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: .4s;

  &.loaded {
    z-index: 30;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &.success {
    width: 400px;
    height: 200px;
  }

  .close-btn {
    position: absolute;
    right: 20px;
    top: 7px;
    font-size: 26px;
    font-family: Verdana, serif;
    color: #fff;
    cursor: pointer;
  }

  .popup-title {
    margin: 0;
    font: 16px RobotoCondensedLight;
    line-height: 50px;
    padding-left: 20px;
    color: #fff;
    background: #08b04d;
    text-transform: uppercase;
    font-weight: bold;
  }

  .one-row {
    width: 90%;
    margin: 15px auto 0;

    label {
      padding-left: 10px;
      font: bold 14px RobotoCondensedLight;
      margin-bottom: 5px;
    }

    input {
      width: 100%;
      height: 30px;
      padding-left: 10px;
      box-sizing: border-box;

      &[type='file'] {
        opacity: 0;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &:hover + .input-file-button {
          background-color: #e3e3e3;
        }
      }
    }

    &.two-col {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .input-wrapper {
        width: 48%;
      }
    }

    textarea {
      width: 100%;
      height: 150px;
      resize: none;
    }

    .input-file-button {
      width: 175px;
      height: 45px;
      margin-top: 0;
      background: #373743;
      color: #e4e4e6;
      font-size: 16px;
      font-family: RobotoCondensedLight;
      cursor: pointer;
      display: block;
      line-height: 45px;
      text-align: center;
      text-transform: uppercase;
      padding: 0;
      position: relative;
    }

    .path-input-file {
      padding-left: 20px;
      display: inline-block;
      margin-right: 15px;
      line-height: 32px;
    }

    .clear-btn {
      color: #dd3b3b;
      font-weight: bold;
      cursor: pointer;
      font-size: 24px;
      position: absolute;
      display: none;
    }
  }

  .user-agreement {
    position: absolute;
    right: 0;
    width: 40%;
    top: 390px;
    font-size: 14px;

    input {
      position: absolute;
      top: 4px;
      left: 0;
      opacity: 0;
      z-index: 500;
    }
  }

  .fr-submit {
    position: absolute;
    right: 0;
    top: 461px;
    background-color: #08b04d;
  }

  .success-message {
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
}